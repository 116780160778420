<template>
  <b-modal
    v-model="onControl"
    title-tag="h3"
    title="ACCESS CREDENTIALS"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    hide-footer
    @hidden="close"
  >
    <div class="d-flex justify-content-end mb-1">
      <b-button
        variant="success"
        @click="openModalEditCredential"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-1"
        />ADD
      </b-button>
    </div>
    <b-table
      responsive
      :fields="fields"
      :items="credentials"
      :busy="isBusy"
      no-border-collapse
      class="position-relative font-small-3"
      show-empty
      sticky-header="50vh"
      primary-key="id"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(username)="data">
        <span>{{ data.item.username }}</span> <br> <span>{{ data.item.created_at | myGlobalDay }}</span>
      </template>
      <template #cell(updatename)="data">
        <span>{{ data.item.updatename }}</span> <br> <span v-if="data.item.updated_at != null">{{ data.item.updated_at | myGlobalDay }}</span>
      </template>
      <template #cell(actions)="data">
        <feather-icon
          icon="EditIcon"
          class="text-primary mr-1 cursor-pointer"
          size="15"
          @click="openCredentialsModal(2, data.item.id)"
        />
        <feather-icon
          icon="TrashIcon"
          class="text-danger cursor-pointer"
          size="15"
          @click="deleteCredential(data.item.id)"
        />
      </template>
    </b-table>
    <edit-add-credential
      v-if="modalEditCredentialOn"
      :id-cred="idToPass"
      :type-modal="2"
      :account-name="accountNumber"
      :name-client="clientName"
      :type="typeModal"
      :account-id-from-cred="credentialId"
      @close="closeModalEditCredential"
      @reload="getCredentials"
    />
  </b-modal>
</template>

<script>
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'
import EditAddCredential from '@/views/commons/components/clients/dashboard/options/acr-module/modals/EditAddCredential.vue'

export default {
  components: {
    EditAddCredential,
  },
  props: {
    credentialId: null,
    accountNumber: null,
    clientName: null,
  },
  data() {
    return {
      onControl: false,
      credentials: [],
      modalEditCredentialOn: false,
      isBusy: false,
      idToPass: '',
      typeModal: '',
      fields: [
        {
          key: 'entity',
          label: 'Entity',
        },
        {
          key: 'user',
          label: 'User',
        },
        {
          key: 'password',
          label: 'Password',
        },
        {
          key: 'observation',
          label: 'Observation',
        },
        {
          key: 'username',
          label: 'Created By',
        },
        {
          key: 'updatename',
          label: 'Updated By',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
    }
  },
  async created() {
    this.onControl = true
    await this.getCredentials()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getCredentials() {
      try {
        this.isBusy = true
        const data = await ClientsOptionsServices.getAllCredentials({
          id: this.credentialId,
          typeOfView: 1,
        })
        if (data.status === 200) {
          this.credentials = data.data
          this.isBusy = false
        }
      } catch (e) {
        this.isBusy = false
        this.showErrorSwal(e)
      }
    },
    openCredentialsModal(type, id) {
      this.typeModal = type
      this.idToPass = id
      this.modalEditCredentialOn = true
    },
    openModalEditCredential() {
      this.modalEditCredentialOn = true
    },
    closeModalEditCredential() {
      this.modalEditCredentialOn = false
    },
    closeCredentialsModal() {
      this.credentialsModalController = false
    },
    async deleteCredential(id) {
      try {
        const result = await this.showConfirmSwal()
        if (result.isConfirmed) {
          const data = await ClientsOptionsServices.deleteCredential({
            idcredential: id,
            id_user: this.currentUser.user_id,
          })
          if (data.status === 200) {
            this.showSuccessSwal()
            await this.getCredentials()
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>

</style>
